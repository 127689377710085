import { useState } from 'react';
import Typography from '@mui/material/Typography';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import { ApplicantAPI } from 'store/api';
import { Card, FlexRow } from 'components/Layouts';
import { formatPhoneNumber } from 'constants/formatters';
import VerifyModal from 'components/VerifyModal';

const ApplicantSection = ({ applicant, handleModalOpen, margin }) => {
  return (
    <>
      <Typography style={{ marginTop: margin ? '20px' : 0 }} variant="h6">{`${applicant?.first_name} ${applicant.last_name}`}</Typography>

      <div style={{ padding: '5px 0px', marginLeft: '10px', boxSizing: 'border-box', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <PhoneIphoneOutlinedIcon fontSize="small" />
        <Typography style={{ marginLeft: '10px' }} variant="body1">
          {applicant?.phone && formatPhoneNumber(applicant.phone)}
        </Typography>
        {applicant?.phone_verified ? (
          <VerifiedIcon style={{ marginLeft: '10px' }} color="primary" fontSize="small" />
        ) : (
          <Button
            onClick={() => handleModalOpen({ to: applicant.phone, applicantId: applicant.id, channel: 'phone' })}
            size="small"
            style={{ marginLeft: '5px' }}
          >
            Verify
          </Button>
        )}
      </div>

      <div style={{ padding: '5px 0px', marginLeft: '10px', boxSizing: 'border-box', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <MailOutlinedIcon fontSize="small" />
        <Typography style={{ marginLeft: '10px' }} variant="body1">
          {applicant?.email}
        </Typography>
        {applicant?.email_verified ? (
          <VerifiedIcon style={{ marginLeft: '10px' }} color="primary" fontSize="small" />
        ) : (
          <Button
            onClick={() => handleModalOpen({ to: applicant.email, applicantId: applicant.id, channel: 'email' })}
            size="small"
            style={{ marginLeft: '5px' }}
          >
            Verify
          </Button>
        )}
      </div>
    </>
  );
};

const Verify = ({ hasCoApplicant, applicant, coApplicant, fetchAppData, appId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [channel, setChannel] = useState(null);
  const [to, setTo] = useState(null);
  const [applicantId, setApplicantId] = useState(null);

  const handleModalOpen = ({ applicantId, channel, to }) => {
    setChannel(channel);
    setTo(to);
    setApplicantId(applicantId);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleVerificationSuccess = () => {
    setChannel(null);
    setTo(null);
    setApplicantId(null);
    fetchAppData();
  };

  return (
    <Card margin="0px 0px 20px" padding="20px" fullwidth variant="outlined" aria-label="Verification.">
      <FlexRow padding="0" margin="0px 0px 20px" alignItems="center" justifyContent="space-between">
        <Typography style={{ margin: '0px' }} variant="h5">
          Verify Contact Information
        </Typography>
      </FlexRow>

      <Alert severity="info" sx={{ mb: '20px' }}>{`The ${
        hasCoApplicant ? "applicants'" : "applicant's"
      } contact information must be verified before they can proceed to signing.`}</Alert>

      <ApplicantSection applicant={applicant} handleModalOpen={handleModalOpen} />

      {hasCoApplicant && <ApplicantSection margin applicant={coApplicant} handleModalOpen={handleModalOpen} />}

      {modalOpen && (
        <VerifyModal
          channel={channel}
          to={to}
          sendOTP={async (data) => {
            const response = await ApplicantAPI.tiggerApplicantOTP({ applicantId, data });

            return response;
          }}
          checkOTP={async (data) => {
            const response = await ApplicantAPI.checkApplicantOtp({ applicantId, data: { ...data, applicationId: appId } });

            return response;
          }}
          open={modalOpen}
          handleSuccess={handleVerificationSuccess}
          handleClose={handleModalClose}
        />
      )}
    </Card>
  );
};

export default Verify;
