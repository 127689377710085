import SearchIcon from '@mui/icons-material/Search';
import { isValidVin } from '@shaggytools/nhtsa-api-wrapper';

import { FlexRow } from 'components/Layouts';
import { TYPES } from 'components/Formie/inputs';
import { STATES } from 'components/Formie/constants';
import {
  stringNotEmpty,
  validateNotNull,
  validateEmail,
  validatePhone,
  validatePastDate110,
  validateSSN,
  validateZip,
  validatePositiveInteger,
} from 'components/Formie/validators';

const applicantFields = (prefix) => ({
  first_name: {
    type: TYPES.text,
    dataPath: `${prefix}.first_name`,
    required: true,
    validator: stringNotEmpty,
    label: 'First Name',
  },
  last_name: {
    type: TYPES.text,
    dataPath: `${prefix}.last_name`,
    required: true,
    validator: stringNotEmpty,
    label: 'Last Name',
  },
  email: {
    type: TYPES.emailLookup,
    dataPath: `${prefix}.email`,
    required: true,
    validator: validateEmail,
    label: (
      <FlexRow inline padding="0px" margin="0px">
        <SearchIcon sx={{ margin: '0px 5px 0px 0px', padding: '0px' }} />
        Email *
      </FlexRow>
    ),
  },
  phone: {
    type: TYPES.phone,
    dataPath: `${prefix}.phone`,
    required: true,
    validator: validatePhone,
    label: 'Phone',
  },
  dob: {
    type: TYPES.date,
    dataPath: `${prefix}.dob`,
    required: true,
    validator: validatePastDate110,
    label: 'Birthday',
  },
  ssn: {
    type: TYPES.masked,
    dataPath: `${prefix}.ssn`,
    required: true,
    validator: validateSSN,
    label: 'SSN',
    mask: '999-99-9999',
  },
  address_1: {
    type: TYPES.text,
    dataPath: `${prefix}.address_1`,
    required: true,
    validator: stringNotEmpty,
    label: 'Address 1',
  },
  address_2: {
    type: TYPES.text,
    dataPath: `${prefix}.address_2`,
    required: false,
    label: 'Address 2',
  },
  city: {
    type: TYPES.text,
    dataPath: `${prefix}.city`,
    required: true,
    validator: stringNotEmpty,
    label: 'City',
  },
  state: {
    type: TYPES.select,
    dataPath: `${prefix}.state`,
    label: 'State',
    required: true,
    validator: validateNotNull,
    options: STATES,
  },
  zip: {
    type: TYPES.masked,
    dataPath: `${prefix}.zip`,
    label: 'Zip Code',
    required: true,
    mask: '99999',
    validator: validateZip,
  },
  address_months_at: {
    type: TYPES.integer,
    dataPath: `${prefix}.address_months_at`,
    required: true,
    validator: validatePositiveInteger,
    label: '# Months Lived Here',
  },
  employment_status: {
    type: TYPES.basicSelect,
    label: 'Employment Status',
    dataPath: `${prefix}.employment_status`,
    required: true,
    options: [
      { value: 'Employed', label: 'Employed' },
      { value: 'Unemployed', label: 'Unemployed' },
      { value: 'Retired', label: 'Retired' },
      { value: 'Self Employed', label: 'Self Employed' },
    ],
    validator: validateNotNull,
  },
  employer: {
    type: TYPES.text,
    dataPath: `${prefix}.employer`,
    required: true,
    validator: stringNotEmpty,
    label: 'Employer Name',
  },
  employer_phone: {
    type: TYPES.phone,
    dataPath: `${prefix}.employer_phone`,
    required: true,
    validator: validatePhone,
    label: 'Employer Phone',
  },
  title: {
    type: TYPES.text,
    dataPath: `${prefix}.title`,
    required: true,
    validator: stringNotEmpty,
    label: 'Title',
  },
  employer_months_at: {
    type: TYPES.integer,
    dataPath: `${prefix}.employer_months_at`,
    required: true,
    validator: validatePositiveInteger,
    label: '# Months Employed Here',
  },
  annual_income: {
    type: TYPES.currency,
    dataPath: `${prefix}.annual_income`,
    required: true,
    validator: validatePositiveInteger,
    label: 'Annual Income',
  },
  home_ownership: {
    type: TYPES.basicSelect,
    dataPath: `${prefix}.home_ownership`,
    required: true,
    options: [
      { value: 'Own', label: 'Own' },
      { value: 'Rent', label: 'Rent' },
      { value: 'Other', label: 'Other' },
    ],
    validator: validateNotNull,
    label: 'Home Ownership',
  },
  home_monthly_payment: {
    type: TYPES.currency,
    dataPath: `${prefix}.home_monthly_payment`,
    required: true,
    validator: validatePositiveInteger,
    label: 'Home Monthly Payment',
  },
});

const sharedUnitAttributes = (prefix) => ({
  vin: {
    type: TYPES.vinLookup,
    dataPath: `${prefix}.vin`,
    required: true,
    label: (
      <FlexRow inline padding="0px" margin="0px">
        <SearchIcon sx={{ margin: '0px 5px 0px 0px', padding: '0px' }} />
        VIN *
      </FlexRow>
    ),
    validator: isValidVin,
  },
  year: {
    type: TYPES.text,
    dataPath: `${prefix}.year`,
    required: true,
    label: 'Year',
    validator: stringNotEmpty,
  },
  make: {
    type: TYPES.text,
    dataPath: `${prefix}.make`,
    required: true,
    label: 'Make',
    validator: stringNotEmpty,
  },
  model: {
    type: TYPES.text,
    dataPath: `${prefix}.model`,
    required: true,
    label: 'Model',
    validator: stringNotEmpty,
  },
  condition: {
    type: TYPES.basicSelect,
    dataPath: `${prefix}.condition`,
    required: true,
    validator: validateNotNull,
    label: 'Condition',
    options: [
      { value: 'New', label: 'New' },
      { value: 'Used', label: 'Used' },
    ],
  },
  mileage: {
    type: TYPES.integer,
    dataPath: `${prefix}.mileage`,
    required: true,
    validator: validatePositiveInteger,
    label: 'Mileage',
  },
});

export const applicationFormConfiguration = ({ application_type_options = [], lender_options = [] }) => {
  return {
    type: {
      id: {
        type: TYPES.basicSelect,
        label: 'Application Type',
        dataPath: 'type.id',
        required: true,
        options: application_type_options || [],
        validator: validateNotNull,
      },
    },
    lender: {
      id: {
        type: TYPES.basicSelect,
        label: 'Lender',
        dataPath: 'lender.id',
        required: true,
        options: lender_options || [],
        validator: validateNotNull,
      },
    },
    applicant: applicantFields('applicant'),
    co_applicant: applicantFields('co_applicant'),
    unit: {
      ...sharedUnitAttributes('unit'),
      price: {
        type: TYPES.currency,
        dataPath: 'unit.price',
        required: true,
        validator: validatePositiveInteger,
        label: 'Sales Price',
      },
      value: {
        type: TYPES.currency,
        dataPath: 'unit.value',
        required: true,
        validator: validatePositiveInteger,
        label: 'Vehicle Value',
      },
      value_source: {
        type: TYPES.basicSelect,
        label: 'Value Source',
        dataPath: 'unit.value_source',
        required: true,
        options: [
          { value: 'JD Power', label: 'JD Power' },
          { value: 'MSRP', label: 'MSRP' },
        ],
        validator: validateNotNull,
      },
    },
    trade_unit: {
      ...sharedUnitAttributes('trade_unit'),
      trade_allowance: {
        type: TYPES.currency,
        dataPath: 'trade_unit.trade_allowance',
        required: true,
        validator: validatePositiveInteger,
        label: 'Trade Allowance',
      },
      payoff_amount: {
        type: TYPES.currency,
        dataPath: 'trade_unit.payoff_amount',
        required: false,
        label: 'Payoff Amount',
      },
      lienholder: {
        type: TYPES.text,
        dataPath: 'trade_unit.lienholder',
        required: false,
        label: 'Lienholder',
      },
    },
  };
};

export const editAppInitialValues = {
  type: {
    id: null,
  },
  lender: {
    id: null,
  },
  applicant: {
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    dob: null,
    ssn: null,
    address_1: null,
    address_2: null,
    city: null,
    state: null,
    zip: null,
    address_months_at: null,
    employment_status: null,
    employer: null,
    employer_phone: null,
    title: null,
    employer_months_at: null,
    annual_income: null,
  },
  co_applicant: {
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    dob: null,
    ssn: null,
    address_1: null,
    address_2: null,
    city: null,
    state: null,
    zip: null,
    address_months_at: null,
    employment_status: null,
    employer: null,
    employer_phone: null,
    title: null,
    employer_months_at: null,
    annual_income: null,
  },
  unit: {
    vin: null,
    year: null,
    make: null,
    model: null,
    condition: null,
    mileage: null,
    price: null,
    value: null,
    value_source: null,
  },
  trade_unit: {
    vin: null,
    year: null,
    make: null,
    model: null,
    condition: null,
    mileage: null,
    trade_allowance: null,
    payoff_amount: null,
  },
};
