import { useContext, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';

import { fetchApplicationById } from 'store/sagas/applications';
import { applicationReferenceSendOTPThunk, applicationReferenceCheckOTPThunk } from 'store/thunks/application';
import { formatPhoneNumber } from 'constants/formatters';
import { ActionMenu } from 'components/Menu';
import VerifyModal from 'components/VerifyModal';
import ReferencesContext from './context';
import UpdateReferenceFormModal from './UpdateReferenceFormModal';
import DeleteReferenceConfirmModal from './DeleteReferenceConfirmModal';

const Reference = ({ reference }) => {
  const { id: reference_id, application_id, first_name, last_name, phone, phone_verified, email, email_verified } = reference;

  const dispatch = useDispatch();

  const { referencesSectionProcessing, setReferencesSectionProcessing } = useContext(ReferencesContext);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showVerifyReferenceModal, setShowVerifyReferenceModal] = useState(false);
  const [verificationChannel, setVerificationChannel] = useState(null);
  const [verificationTo, setVerificationTo] = useState(null);

  const actionOptions = useMemo(() => {
    let options = [];

    if (!phone_verified) {
      options.push({
        value: 'Verify Phone',
        icon: <PhoneOutlinedIcon />,
        callback: () => {
          setReferencesSectionProcessing(true);
          setShowVerifyReferenceModal(true);
          setVerificationChannel('phone');
          setVerificationTo(phone);
        },
      });
    }

    if (!email_verified) {
      options.push({
        value: 'Verify Email',
        icon: <EmailOutlinedIcon />,
        callback: () => {
          setReferencesSectionProcessing(true);
          setShowVerifyReferenceModal(true);
          setVerificationChannel('email');
          setVerificationTo(email);
        },
      });
    }

    if (!phone_verified || !email_verified) {
      options.push({ divider: true });
    }

    options.push({
      value: 'Edit',
      icon: <EditOutlinedIcon />,
      callback: () => {
        setReferencesSectionProcessing(true);
        setShowUpdateModal(true);
      },
    });

    options.push({
      value: <Typography sx={{ color: 'red' }}>Delete</Typography>,
      icon: <DeleteOutlineOutlinedIcon sx={{ color: 'red' }} />,
      callback: () => {
        setReferencesSectionProcessing(true);
        setShowConfirmDeleteModal(true);
      },
    });

    return options;
  }, [phone_verified, email_verified]);

  return (
    <>
      <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
        <Card>
          <CardHeader
            sx={{ backgroundColor: '#5301FF', minHeight: '60px' }}
            avatar={
              <Avatar sx={{ width: 52, height: 52, backgroundColor: '#fff' }} aria-label="Bank logo">
                <PersonIcon sx={{ width: 38, height: 38, color: '#5301FF' }} />
              </Avatar>
            }
            action={
              showUpdateModal || showConfirmDeleteModal || showVerifyReferenceModal ? (
                <CircularProgress sx={{ color: '#fff' }} />
              ) : referencesSectionProcessing ? (
                <></>
              ) : (
                <ActionMenu sx={{ color: '#fff' }} options={actionOptions} />
              )
            }
            title={
              <Typography sx={{ color: '#fff' }} variant="subtitle2" gutterBottom>
                {`${first_name} ${last_name}`}
              </Typography>
            }
            subheader={
              phone_verified && email_verified ? (
                <Chip size="small" label="Verified" color="success" />
              ) : !phone_verified && !email_verified ? (
                <Chip size="small" label="Unverified" color="error" />
              ) : (
                <Chip size="small" label="Partially Verified" color="warning" />
              )
            }
          />

          <CardContent>
            <Stack direction="row" spacing={1} alignContent={'center'} alignItems={'center'}>
              <Typography sx={{ fontWeight: 600, marginBottom: 0 }} variant="body2" gutterBottom>
                Phone:
              </Typography>

              <Typography variant="body2">{formatPhoneNumber(phone)}</Typography>

              {phone_verified ? (
                <Typography color="primary" sx={{ fontSize: '18px' }} variant="body2" gutterBottom>
                  <VerifiedIcon sx={{ fontSize: 'inherit', position: 'relative', top: '2px' }} />
                </Typography>
              ) : (
                <Typography sx={{ color: 'red', fontSize: '18px' }} variant="body2" gutterBottom>
                  <NewReleasesOutlinedIcon sx={{ fontSize: 'inherit', position: 'relative', top: '2px' }} />
                </Typography>
              )}
            </Stack>

            <Stack direction="row" spacing={1} alignContent={'center'} alignItems={'center'}>
              <Typography sx={{ fontWeight: 600, marginBottom: 0 }} variant="body2" gutterBottom>
                Email:
              </Typography>

              <Typography variant="body2">{email}</Typography>

              {email_verified ? (
                <Typography color="primary" sx={{ fontSize: '18px' }} variant="body2" gutterBottom>
                  <VerifiedIcon sx={{ fontSize: 'inherit', position: 'relative', top: '2px' }} />
                </Typography>
              ) : (
                <Typography sx={{ color: 'red', fontSize: '18px' }} variant="body2" gutterBottom>
                  <NewReleasesOutlinedIcon sx={{ fontSize: 'inherit', position: 'relative', top: '2px' }} />
                </Typography>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      {showUpdateModal && (
        <UpdateReferenceFormModal
          applicationId={application_id}
          referenceId={reference_id}
          firstName={first_name}
          lastName={last_name}
          phone={phone}
          email={email}
          onClose={() => {
            setShowUpdateModal(false);
            setReferencesSectionProcessing(false);
          }}
        />
      )}

      {showConfirmDeleteModal && (
        <DeleteReferenceConfirmModal
          applicationId={application_id}
          referenceId={reference_id}
          firstName={first_name}
          lastName={last_name}
          phone={phone}
          email={email}
          onClose={() => {
            setShowConfirmDeleteModal(false);
            setReferencesSectionProcessing(false);
          }}
        />
      )}

      {showVerifyReferenceModal && (
        <VerifyModal
          channel={verificationChannel}
          to={verificationTo}
          sendOTP={async (data) => {
            const response = await dispatch(
              applicationReferenceSendOTPThunk({
                applicationId: application_id,
                referenceId: reference_id,
                data,
              }),
            ).unwrap();

            return response;
          }}
          checkOTP={async (data) => {
            const response = await dispatch(
              applicationReferenceCheckOTPThunk({
                applicationId: application_id,
                referenceId: reference_id,
                data,
              }),
            ).unwrap();

            return response;
          }}
          open={showVerifyReferenceModal}
          readOnly={true} // Don't allow editing of phone and email during verification
          handleSuccess={() => {
            dispatch(fetchApplicationById(application_id)); // Fetch latest current application data
          }}
          handleClose={() => {
            setVerificationTo(null);
            setVerificationChannel(null);
            setShowVerifyReferenceModal(false);
            setReferencesSectionProcessing(false);
          }}
        />
      )}
    </>
  );
};

export default Reference;
