import { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SaveIcon from '@mui/icons-material/Save';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { getDealerLendersThunk } from 'store/thunks/dealer';
import Applicant from './applicant';
import Unit from './unit';
import BasicInfo from './basicInfo';
import NavBar from 'components/NavBar';
import { UnderNavBarContainer, FlexRow, Card } from 'components/Layouts';
import { applicationFormConfiguration, editAppInitialValues } from '../formInfo';
import {
  updateApplication,
  storeApplication,
  fetchApplicationById,
  createApplication,
  submitApplication,
  fetchApplicationTypes,
} from 'store/sagas/applications';
import { useIsMount, calculateProgress, calculateSavability } from './../utils';
import { LINKS } from 'constants/menus';
import Sidebar from './sidebar';
import { COLORS } from 'constants/styles';
import { STATUS_LABELS } from 'constants/status';
import { roundAccurately } from 'constants/formatters';
import { useFormie } from 'components/Formie';
const { SAVED } = STATUS_LABELS;

export const SECTIONS = {
  BASIC_INFO: 'basic_info',
  APPLICANT: 'applicant',
  CO_APPLICANT: 'co_applicant',
  UNIT: 'unit',
  TRADE_IN: 'trade_in',
  REVIEW: 'review',
};

const buildBreadcrumbs = (id, currentApplication) => [
  id == 'new' ? LINKS.DEALER.ALL_APPLICATIONS : LINKS.DEALER.VIEW_APPLICATION(id),
  [
    { text: 'Home', link: LINKS.DEALER.DASHBOARD },
    { text: 'Applications', link: LINKS.DEALER.ALL_APPLICATIONS },
    {
      text:
        id == 'new'
          ? 'New App'
          : currentApplication?.applicant?.first_name && currentApplication?.applicant?.last_name
          ? `${currentApplication.applicant.first_name} ${currentApplication.applicant.last_name}`
          : currentApplication?.applicant?.email
          ? currentApplication.applicant.email
          : 'View Application',
      link: id == 'new' ? '' : LINKS.DEALER.VIEW_APPLICATION(id),
    },
    { text: 'Edit', link: '' },
  ],
];

const buildNavBarActions = (saveApp, progress, canSave) => [
  {
    title: 'Submit',
    action: () => saveApp(true, true),
    disabled: progress?.totalProgress < 1,
    icon: <PostAddOutlinedIcon size="small" />,
  },
  {
    title: 'Save',
    action: saveApp,
    disabled: !canSave,
    icon: <SaveIcon size="small" />,
  },
  {
    title: 'Save & Exit',
    action: () => saveApp(true),
    disabled: !canSave,
    icon: <ExitToAppIcon size="small" />,
  },
];

const EditApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const appLoading = useSelector((state) => state.app.loading);
  const currentApplication = useSelector((state) => state.applications.currentApplication);
  const applicationTypes = useSelector((state) => state.applications.application_types);
  const applicationTypesLoading = useSelector((state) => state.applications.loading.application_types);
  const application_type_options = applicationTypes?.map((type) => ({ value: type.id, label: type.name })) || [];

  const underwriting_status = currentApplication && currentApplication.underwriting && currentApplication.underwriting.underwriting_status;
  const underwriting_status_details = currentApplication && currentApplication.underwriting && currentApplication.underwriting.underwriting_status_details;

  const [showCoApplicant, setShowCoApplicant] = useState(false);
  const [showTradeIn, setShowTradeIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [shouldExit, setShouldExit] = useState(false);
  const [lender_options, set_lender_options] = useState([]);

  const { id: urlId } = useParams();
  const [searchParams] = useSearchParams();
  const activeSection = searchParams.get('section');
  const [backButton, breadcrumbs] = buildBreadcrumbs(urlId, currentApplication);
  let isMount = useIsMount();

  const formie = useFormie({
    configuration: applicationFormConfiguration({ application_type_options, lender_options }),
    initialValues: editAppInitialValues,
    setLoading: setLoading,
  });
  const { values, errors } = formie;
  const progress = calculateProgress({ values: values, errors: errors, hasCoApplicant: showCoApplicant, hasTradeIn: showTradeIn });
  const [canSave, hasValidUnitVIN, hasValidTradeInVIN] = calculateSavability({ values, hasCoApplicant: showCoApplicant, hasTradeIn: showTradeIn });

  const resetLocalState = () => {
    setShowCoApplicant(false);
    setShowTradeIn(false);
    setLoading(false);
    setShouldExit(false);
  };

  const storeFromCurrentApplication = () => {
    resetLocalState();
    const { status, co_applicant, trade_unit, id, applicant } = currentApplication;
    const statusName = status && status.name;

    if (!!statusName && ![SAVED].includes(statusName)) navigate(LINKS.DEALER.VIEW_APPLICATION(id));

    if (shouldExit && !submitted) navigate(LINKS.DEALER.ALL_APPLICATIONS);
    else if (shouldExit && submitted && id) navigate(LINKS.DEALER.VIEW_APPLICATION(id));

    if (urlId == 'new' && id) navigate(LINKS.DEALER.EDIT_APPLICATION(id));
    if (urlId !== 'new') formie.setAllValues(currentApplication);
    if (co_applicant?.id) setShowCoApplicant(true);
    if (trade_unit?.id) setShowTradeIn(true);

    if (applicant?.id) {
      formie.resetCheckedEmails(co_applicant?.id ? { applicant: true, co_applicant: true } : { applicant: true });
    }
  };

  useEffect(() => {
    const fetchDealerLenders = async () => {
      try {
        const lenders = await dispatch(getDealerLendersThunk({ dealerId: user?.active_profile?.dealer_id, acceptingApplication: true })).unwrap();

        set_lender_options(lenders.filter((lender) => lender.accepting_application).map((lender) => ({ value: lender.id, label: lender.name })));
      } catch (err) {
        console.log('Failed to fetch dealer lenders: ', err);
      }
    };

    if (user?.active_profile?.dealer_id) {
      fetchDealerLenders();
    }
  }, [user?.active_profile?.dealer_id]);

  useEffect(() => {
    dispatch(fetchApplicationTypes());

    if (urlId != 'new') {
      dispatch(fetchApplicationById(urlId));
    } else {
      dispatch(storeApplication({}));
    }

    return () => {
      dispatch(storeApplication({}));
    };
  }, [urlId]);

  useEffect(() => {
    if (!isMount) storeFromCurrentApplication();
  }, [currentApplication]);

  const saveApp = (exit = false, submit = false) => {
    /**
     * ! Important
     *
     * We need to do this this way when creating applicantData, coApplicantData, unitData and tradeInData variables.
     * The reason we are doing it this way is it creates an object that is readable and writable.
     * If we just assign directly the values to the variables above, we cannot modify its value
     * below when we set title and employer_phone to undefined based on employment_status.
     */
    let applicantData = { ...values?.applicant };
    let coApplicantData = showCoApplicant ? { ...values?.co_applicant } : null;
    let unitData = hasValidUnitVIN ? { ...values.unit } : null;
    let tradeInData = showTradeIn && hasValidTradeInVIN ? { ...values.trade_unit } : null;

    if (tradeInData) {
      const tradeAllowance = tradeInData.trade_allowance || 0;
      const tradePayoff = tradeInData.payoff_amount || 0;

      tradeInData.equity = roundAccurately(tradeAllowance - tradePayoff, 2);
    }

    setShouldExit(!!exit);
    setSubmitted(submit);

    if (['Unemployed', 'Retired'].includes(applicantData.employment_status)) {
      applicantData.title = undefined;
      applicantData.employer_phone = undefined;
    }

    if (coApplicantData && ['Unemployed', 'Retired'].includes(coApplicantData.employment_status)) {
      coApplicantData.title = undefined;
      coApplicantData.employer_phone = undefined;
    }

    const dataObj = {
      applicant: applicantData,
      co_applicant: coApplicantData,
      unit: unitData,
      trade_unit: tradeInData,
      application_type_id: values?.type?.id,
      lender_id: values?.lender?.id,
      dealer_id: user?.active_profile?.dealer_id,
    };

    if (submit) {
      dispatch(submitApplication({ data: dataObj, applicationId: urlId }));
    } else if (urlId == 'new') {
      dispatch(createApplication({ data: dataObj }));
    } else {
      dispatch(updateApplication({ data: dataObj, applicationId: urlId }));
    }
  };

  return (
    <Fragment>
      <NavBar backButton={backButton} breadcrumbs={breadcrumbs} actions={buildNavBarActions(saveApp, progress, canSave)} />
      <LinearProgress variant="determinate" value={progress?.totalProgress * 100} />
      {applicationTypesLoading ? (
        <FlexRow fullHeight justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
        </FlexRow>
      ) : (
        <UnderNavBarContainer padding="0px" withProgress>
          <FlexRow fullHeight padding="0px">
            <Sidebar appId={urlId} activeSection={activeSection} SECTIONS={SECTIONS} />

            <FlexRow alignItems="center" overflowScroll fullHeight flexColumn padding="20px 20px 0px">
              {urlId !== 'new' && !currentApplication.id && !appLoading ? (
                <Card margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Application not found.">
                  <Alert severity="error">
                    <AlertTitle>Application not found.</AlertTitle>
                  </Alert>
                </Card>
              ) : (
                <>
                  {underwriting_status && (
                    <Card margin="0px 0px 20px" padding="0px" fullwidth variant="outlined" aria-label="Underwriting error.">
                      <Alert severity="error">
                        <AlertTitle>{underwriting_status}</AlertTitle>
                        {underwriting_status_details}
                      </Alert>
                    </Card>
                  )}

                  {(activeSection === SECTIONS.BASIC_INFO || activeSection === SECTIONS.REVIEW || !activeSection) && (
                    <BasicInfo
                      progress={progress.basicInfoProgress}
                      continueFn={() => navigate(LINKS.DEALER.EDIT_APPLICATION(urlId, SECTIONS.APPLICANT))}
                      activeSection={activeSection}
                      section={SECTIONS.BASIC_INFO}
                      formie={formie}
                    />
                  )}

                  {(activeSection === SECTIONS.APPLICANT || activeSection === SECTIONS.REVIEW) && (
                    <Applicant
                      emailChecked={formie?.checkedEmails?.applicant}
                      activeSection={activeSection}
                      progress={progress.applicantProgress}
                      section={SECTIONS.APPLICANT}
                      continueFn={() => navigate(LINKS.DEALER.EDIT_APPLICATION(urlId, SECTIONS.CO_APPLICANT))}
                      formie={formie}
                    />
                  )}

                  {(activeSection === SECTIONS.CO_APPLICANT || activeSection === SECTIONS.REVIEW) && (
                    <Applicant
                      showCoApplicant={showCoApplicant}
                      setShowCoApplicant={setShowCoApplicant}
                      isCoApplicant={true}
                      emailChecked={formie?.checkedEmails?.co_applicant}
                      activeSection={activeSection}
                      progress={progress.coApplicantProgress}
                      section={SECTIONS.CO_APPLICANT}
                      continueFn={() => navigate(LINKS.DEALER.EDIT_APPLICATION(urlId, SECTIONS.UNIT))}
                      formie={formie}
                    />
                  )}

                  {(activeSection === SECTIONS.UNIT || activeSection === SECTIONS.REVIEW) && (
                    <Unit
                      activeSection={activeSection}
                      progress={progress.unitProgress}
                      section={SECTIONS.UNIT}
                      continueFn={() => navigate(LINKS.DEALER.EDIT_APPLICATION(urlId, SECTIONS.TRADE_IN))}
                      formie={formie}
                    />
                  )}

                  {(activeSection === SECTIONS.TRADE_IN || activeSection === SECTIONS.REVIEW) && (
                    <Unit
                      showTradeIn={showTradeIn}
                      setShowTradeIn={setShowTradeIn}
                      activeSection={activeSection}
                      progress={progress.tradeInProgress}
                      section={SECTIONS.TRADE_IN}
                      continueFn={() => navigate(LINKS.DEALER.EDIT_APPLICATION(urlId, SECTIONS.REVIEW))}
                      isTradeIn
                      formie={formie}
                    />
                  )}
                </>
              )}
            </FlexRow>
          </FlexRow>
        </UnderNavBarContainer>
      )}

      <Backdrop sx={{ color: COLORS.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default EditApp;
